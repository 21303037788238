import React from "react";
import GatsbyLink from "../src/components/GatsbyLink";
import "./Logo.scss";

export const Logo = () => {
  return (
    <GatsbyLink to="/" className="logo">
      <svg
        width="180"
        height="39"
        viewBox="0 0 180 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M5.93048 39C4.35834 38.9982 2.85108 38.3714 1.73931 37.2569C0.62754 36.1423 0.0020359 34.6312 0 33.0549V5.94667C0.00161591 4.37011 0.626934 2.85857 1.73875 1.74372C2.85057 0.628866 4.35807 0.00176081 5.93048 0H32.9663C34.5389 0.0015445 36.0466 0.628542 37.1586 1.74341C38.2707 2.85828 38.8961 4.36994 38.8978 5.94667V33.0549C38.8957 34.6314 38.2701 36.1426 37.1581 37.2572C36.0461 38.3717 34.5386 38.9985 32.9663 39H5.93048Z"
            fill="#003964"
          />
          <path
            d="M89.916 34.6492H83.7173C83.4614 34.6456 83.2126 34.5644 83.0037 34.4162C82.7948 34.268 82.6356 34.0598 82.5471 33.8191C82.0529 32.8197 77.8953 22.8795 77.8953 22.8795L76.9795 22.8837C76.7261 22.8746 76.4795 22.9666 76.2938 23.1397C76.1081 23.3127 75.9986 23.5526 75.9891 23.8066V33.2282C76.0025 33.6049 75.8662 33.9715 75.6102 34.2476C75.3542 34.5236 74.9994 34.6866 74.6237 34.7006L69.6613 34.6492C69.2858 34.6623 68.9203 34.5254 68.6453 34.2687C68.3703 34.0119 68.2081 33.6562 68.1943 33.2796V5.74756C68.2084 5.37132 68.3708 5.01606 68.6458 4.7597C68.9209 4.50334 69.2861 4.36683 69.6613 4.3801L82.5386 4.32812C86.5815 4.32812 89.8573 7.86291 89.8573 12.2194V14.0584C89.8824 15.5683 89.4766 17.054 88.6877 18.3405C87.8988 19.6269 86.7596 20.6605 85.4044 21.3194C85.9128 22.5283 90.0985 32.4702 90.6778 33.8191C90.8593 34.2397 90.336 34.6492 89.916 34.6492V34.6492ZM82.1513 12.8118C82.168 12.3216 81.9903 11.8447 81.657 11.4856C81.3237 11.1265 80.8621 10.9145 80.3733 10.8959L76.9795 10.8965C76.7262 10.8873 76.4797 10.9793 76.294 11.1522C76.1083 11.3252 75.9987 11.5649 75.9891 11.8189L76.0256 15.7526C76.0165 16.0062 76.108 16.253 76.2802 16.439C76.4524 16.625 76.6911 16.7348 76.944 16.7445L80.3066 16.7439C80.7953 16.761 81.2707 16.5831 81.6287 16.2492C81.9867 15.9153 82.1981 15.4526 82.2164 14.9627L82.1513 12.8118Z"
            fill="#1A1A1A"
          />
          <path
            d="M155.063 33.2283C155.076 33.6048 154.94 33.9713 154.684 34.2473C154.428 34.5234 154.074 34.6864 153.698 34.7007H142.135C138.905 34.7007 136.284 31.8046 136.284 28.2337V10.7931C136.284 7.22119 138.905 4.32715 142.135 4.32715L153.697 4.3282C154.072 4.3423 154.427 4.50506 154.683 4.78079C154.939 5.05651 155.075 5.42271 155.062 5.79909V9.47605C155.048 9.85241 154.886 10.2078 154.611 10.4643C154.336 10.7208 153.97 10.8574 153.595 10.844L145.922 10.9623C145.434 10.9453 144.958 11.1232 144.6 11.4571C144.242 11.7909 144.03 12.2535 144.011 12.7435L144.078 26.2685C144.061 26.7585 144.238 27.2353 144.571 27.5944C144.904 27.9536 145.366 28.1659 145.854 28.1849H153.595C153.97 28.1714 154.336 28.3078 154.611 28.5642C154.886 28.8206 155.049 29.176 155.063 29.5524V33.2283Z"
            fill="#1A1A1A"
          />
          <path
            d="M61.0631 22.0122C61.4389 22.0255 61.8045 21.8887 62.0798 21.6319C62.355 21.3752 62.5174 21.0193 62.5312 20.6426L62.4799 17.0352C62.493 16.6587 62.3567 16.2924 62.1008 16.0165C61.845 15.7407 61.4904 15.5779 61.115 15.5637H54.9586C54.7055 15.5542 54.4665 15.4444 54.2939 15.2585C54.1214 15.0726 54.0294 14.8257 54.0381 14.5718L54.0042 11.8194C54.0134 11.5658 54.1226 11.3262 54.3078 11.1532C54.4929 10.9802 54.739 10.8879 54.9919 10.8965L63.6215 10.8439C63.997 10.8575 64.3625 10.7211 64.6379 10.4647C64.9132 10.2083 65.0758 9.85295 65.0902 9.47649V5.79955C65.1034 5.42306 64.9671 5.05668 64.7112 4.78082C64.4553 4.50497 64.1007 4.34216 63.7252 4.32812L47.4712 4.3801C47.0958 4.36703 46.7307 4.50369 46.4557 4.76012C46.1807 5.01655 46.0183 5.37183 46.0042 5.74809V33.2802C46.0181 33.6566 46.1804 34.0121 46.4554 34.2688C46.7304 34.5254 47.0957 34.6622 47.4712 34.6492L64.7479 34.7007C65.1236 34.6867 65.4784 34.5239 65.7345 34.2479C65.9906 33.972 66.127 33.6054 66.1138 33.2287V29.5528C66.0996 29.1763 65.9369 28.8209 65.6616 28.5644C65.3863 28.308 65.0208 28.1715 64.6452 28.1848H54.9586C54.7054 28.1752 54.4664 28.0653 54.2939 27.8793C54.1213 27.6933 54.0293 27.4463 54.0381 27.1924L54.0042 22.9341C54.0135 22.6806 54.1227 22.4411 54.3079 22.2683C54.4931 22.0955 54.7391 22.0034 54.9919 22.0122H61.0631Z"
            fill="#1A1A1A"
          />
          <path
            d="M104.661 34.7006C105.037 34.6863 105.391 34.5232 105.647 34.2472C105.903 33.9711 106.039 33.6047 106.026 33.2282V11.8194C106.035 11.5658 106.144 11.3262 106.329 11.1531C106.515 10.9801 106.761 10.8878 107.014 10.8965L111.74 10.8434C112.115 10.8566 112.48 10.72 112.755 10.4637C113.03 10.2073 113.192 9.85212 113.206 9.47596V5.799C113.219 5.4227 113.083 5.05656 112.827 4.78084C112.571 4.50512 112.217 4.34233 111.842 4.32812H92.586C92.2106 4.3421 91.856 4.50479 91.6 4.78053C91.344 5.05627 91.2076 5.42253 91.2206 5.799V9.47596C91.2346 9.85231 91.3971 10.2077 91.6722 10.4641C91.9474 10.7205 92.3127 10.8569 92.6881 10.8434L97.3114 10.8625C97.5645 10.8717 97.8037 10.9813 97.9764 11.1672C98.1491 11.353 98.2412 11.6 98.2324 11.8539L98.2308 33.3199C98.2451 33.6962 98.4076 34.0515 98.6827 34.308C98.9578 34.5644 99.323 34.701 99.6984 34.6879L104.661 34.7006Z"
            fill="#1A1A1A"
          />
          <path
            d="M178.635 34.7007C179.011 34.6863 179.365 34.5233 179.621 34.2473C179.877 33.9713 180.013 33.6048 180 33.2282V5.798C180.013 5.42169 179.876 5.0556 179.621 4.77998C179.365 4.50436 179.01 4.34169 178.635 4.32764L173.672 4.37856C173.297 4.36541 172.931 4.50208 172.656 4.75863C172.381 5.01517 172.219 5.37064 172.205 5.74707L172.171 14.9315C172.179 15.1853 172.087 15.4323 171.915 15.6181C171.742 15.804 171.503 15.9136 171.25 15.9229H165.892C165.639 15.9138 165.4 15.8042 165.227 15.6183C165.054 15.4324 164.962 15.1854 164.971 14.9315L164.972 5.798C164.985 5.42166 164.849 5.05551 164.593 4.77987C164.337 4.50422 163.983 4.3416 163.607 4.32764L158.645 4.37856C158.269 4.36547 157.904 4.50213 157.629 4.75863C157.353 5.01513 157.191 5.37057 157.176 5.74707V33.2797C157.19 33.6563 157.353 34.012 157.628 34.2687C157.904 34.5255 158.269 34.6623 158.645 34.6492L163.607 34.7007C163.983 34.6864 164.337 34.5234 164.593 34.2474C164.849 33.9713 164.985 33.6048 164.972 33.2282L164.937 23.447C164.946 23.1932 165.056 22.9534 165.241 22.7803C165.426 22.6072 165.673 22.5149 165.926 22.5236H171.217C171.47 22.5151 171.716 22.6075 171.901 22.7806C172.087 22.9537 172.196 23.1933 172.205 23.447L172.205 33.2797C172.218 33.6563 172.381 34.012 172.656 34.2687C172.931 34.5255 173.296 34.6624 173.672 34.6492L178.635 34.7007Z"
            fill="#1A1A1A"
          />
          <path
            d="M133.794 34.7006C134.169 34.6864 134.524 34.5233 134.78 34.2473C135.036 33.9713 135.172 33.6048 135.159 33.2282V29.5523C135.144 29.176 134.982 28.8208 134.707 28.5645C134.432 28.3082 134.067 28.1716 133.691 28.1848H124.004C123.75 28.1752 123.511 28.0652 123.339 27.879C123.167 27.6929 123.075 27.4457 123.084 27.1919L123.049 22.9341C123.059 22.6804 123.168 22.4408 123.354 22.268C123.539 22.0951 123.785 22.0031 124.038 22.0122H130.109C130.484 22.0255 130.85 21.8888 131.125 21.632C131.401 21.3752 131.563 21.0194 131.577 20.6426L131.525 17.0346C131.538 16.6583 131.402 16.2921 131.146 16.0164C130.891 15.7406 130.536 15.5778 130.161 15.5637H124.004C123.75 15.554 123.512 15.4441 123.339 15.2581C123.167 15.072 123.075 14.8251 123.084 14.5713L123.049 11.8194C123.059 11.5656 123.168 11.3259 123.353 11.1529C123.539 10.9798 123.785 10.8876 124.038 10.8965L132.668 10.8434C133.043 10.8568 133.408 10.7204 133.684 10.464C133.959 10.2077 134.121 9.85233 134.136 9.47596V5.799C134.149 5.42256 134.012 5.05635 133.756 4.78063C133.5 4.50491 133.146 4.34219 132.77 4.32812L116.517 4.3801C116.141 4.36699 115.776 4.50354 115.501 4.75985C115.226 5.01615 115.063 5.37133 115.049 5.74756V33.2796C115.063 33.6562 115.225 34.0118 115.501 34.2685C115.776 34.5252 116.141 34.6621 116.517 34.6492L133.794 34.7006Z"
            fill="#1A1A1A"
          />
          <path
            d="M34.6414 22.0671C34.6414 22.2504 34.6053 22.4319 34.5353 22.6013C34.4653 22.7707 34.3627 22.9246 34.2333 23.0542C34.1039 23.1838 33.9504 23.2865 33.7814 23.3566C33.6124 23.4267 33.4313 23.4627 33.2485 23.4626L31.9555 23.4637H14.0821V26.1853H32.2782L33.2485 26.1837C33.6179 26.1839 33.9721 26.3311 34.2333 26.5929C34.4945 26.8548 34.6413 27.21 34.6414 27.5804V33.3059C34.641 33.676 34.494 34.0308 34.2328 34.2923C33.9717 34.5539 33.6176 34.7009 33.2485 34.7009H5.74077C5.37173 34.7008 5.01782 34.5538 4.75682 34.2923C4.49582 34.0307 4.34909 33.6759 4.34888 33.3059V17.0651C4.34873 16.8817 4.38462 16.7001 4.45451 16.5307C4.52439 16.3613 4.6269 16.2073 4.75616 16.0776C4.88542 15.9479 5.03892 15.845 5.20786 15.7748C5.37681 15.7046 5.55789 15.6684 5.74077 15.6685H33.2485C33.4314 15.6684 33.6126 15.7045 33.7816 15.7747C33.9506 15.8449 34.1042 15.9478 34.2335 16.0775C34.3629 16.2071 34.4655 16.3611 34.5355 16.5306C34.6055 16.7 34.6415 16.8817 34.6414 17.0651V22.0671V22.0671Z"
            fill="white"
          />
          <path
            d="M5.74101 4.32813C5.55817 4.3281 5.37712 4.36419 5.20821 4.43435C5.03929 4.50451 4.88581 4.60735 4.75655 4.73701C4.62729 4.86666 4.52478 5.02057 4.45487 5.18996C4.38496 5.35935 4.34903 5.5409 4.34912 5.72422V11.5515C4.34933 11.9216 4.49606 12.2763 4.75706 12.5379C5.01806 12.7995 5.37197 12.9465 5.74101 12.9466H13.858V4.32813H5.74101Z"
            fill="#999999"
          />
          <path
            d="M33.2487 4.32813H16.3826V12.9466H33.2487C33.6179 12.9466 33.972 12.7997 34.2332 12.5381C34.4944 12.2766 34.6413 11.9217 34.6417 11.5515V5.72422C34.6417 5.54083 34.6057 5.35923 34.5357 5.1898C34.4657 5.02038 34.3631 4.86645 34.2337 4.73679C34.1044 4.60714 33.9508 4.50432 33.7818 4.4342C33.6128 4.36408 33.4316 4.32804 33.2487 4.32813"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="180" height="39" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </GatsbyLink>
  );
};
